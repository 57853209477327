<template>
    <div>
        <div style="padding:40px">
            <div style="display:flex;margin-bottom:20px">
                <el-input placeholder="请输入学生姓名/手机号/线索id " class="inPut" v-model="query.info"></el-input>
                <el-autocomplete
                    style="margin-right:10px ;"
                    v-model="query.createName"
                    :fetch-suggestions="querySearchAsync"
                    placeholder="教练-老师名称"
                    :trigger-on-focus="false"
                    :clearable="true"
                    @select="selectCocahName"
                    /> 
                <!-- <el-input placeholder="请输入签单人" class="inPut" v-model="query.signName"></el-input> -->
                <el-date-picker
                    v-model="v1"
                    style="max-width:240px;margin-right:10px"
                    type="daterange"
                    range-separator="至"
                    @change="disposeTime"
                    start-placeholder="创建开始时间"
                    end-placeholder="创建结束时间"
                    :clearable="false"
                    >
                </el-date-picker>
                <el-cascader
                    class="mr10"
                    collapse-tags
                    collapse-tags-tooltip
                    clearable
                    v-model="query.createDeptId"
                    placeholder="请选择部门"
                    :props="{
                        multiple: true,
                        value: 'id',
                        label: 'name',
                        children: 'children'}"
                    :options="deptList"/>
                <el-select placeholder="请选择试课状态" v-model="query.state"  class="inPut">
                    <el-option  :value="0" label="全部"></el-option>
                    <el-option  :value="1" label="未试课"></el-option>
                    <el-option  :value="2" label="已试课"></el-option>
                </el-select>
                <el-button type="primary" @click="getData">搜索</el-button>
                <el-popconfirm @confirm="exportExcel" title="确定要导出吗？">
                    <template #reference>
                        <el-button>导出</el-button>
                    </template>
                </el-popconfirm>
            </div>
            <el-table :data="dataList" border  class="table el-table__cell"
                :row-style="{height:40+'px'}"
                :cell-style="{fontSize:'13px',padding:'15px 0px'}"
                >
                    <el-table-column fixed  label="提交教练" prop="createName" width="110" />
                    <el-table-column fixed  label="城市类型"  width="110" >
                        <template #default="scope"> 
                            <div>{{ scope.row.city + '-' + scope.row.flag }}</div>
                        </template>
                    </el-table-column>
                    <el-table-column label="线索创建人" prop="crmCreateName"  width="130"/>
                    <el-table-column label="线索创建时间" prop="crmCreateTime"  width="150"/>
                    <el-table-column label="试课校区" prop="areaName"  width="130"/>
                    <el-table-column label="创建时间" prop="createTime" width="150" />
                    <el-table-column label="申请试课时间" prop="trialClassTime" width="150" />
                    <el-table-column label="成单时间" prop="isEndOrderTime" width="150" />
                    <el-table-column label="试课教练" prop="coachName" width="90"/>
                    <el-table-column label="教练试课时间" prop="coachTrialClassTime" width="150"  />
                    <el-table-column label="试课状态">
                        <template #default="scope"> 
                            <div> <el-tag type="danger" v-if="scope.row.state == 1">未试课</el-tag>
                                <el-tag  v-if="scope.row.state == 2">已试课</el-tag> </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="试课学员姓名">
                        <template #default="scope"> 
                            <div>{{ scope.row.studentRecord.studentName }}</div>
                        </template>
                    </el-table-column>
                    <el-table-column label="试课学员学校-班级" show-overflow-tooltip width="150">
                        <template #default="scope"> 
                            <div>{{ scope.row.studentRecord.studentSchool?scope.row.studentRecord.studentSchool:'' + '-' + scope.row.studentRecord.studentClass?scope.row.studentRecord.studentClass:'' }}</div>
                        </template>
                    </el-table-column>
                    <el-table-column   label="试课学员联系方式" width="150">
                        <template #default="scope"> 
                            <div>{{ scope.row.studentRecord.studentPhone }}</div>
                        </template>
                    </el-table-column>
                    <!-- <el-table-column label="操作"  fixed="right">
                        <template #default="scope"> 
                            <el-popover placement="left"  width="150" trigger="click">
                                <div class="space-around" style="text-align: center;">
                                <el-button
                                type="text"
                                @click="editOrder(scope.row)"
                                >
                                编辑
                                </el-button>   
                                <el-button
                                type="text"
                                @click="bindingOrder(scope.row)"
                                >
                                绑定
                                </el-button>
                                <el-button
                                type="text"
                                style="color: red;"
                                @click="returnMoney(scope.row)"
                                >
                                退费
                                </el-button>        
                                </div>        
                                <template #reference>
                                    <el-button size="small"  type="text">操作</el-button>
                                </template>
                            </el-popover>
                        </template>
                    </el-table-column> -->
            </el-table>
            <div class="pagination">
                <el-pagination
                    background
                    layout="total, prev, pager, next"
                    :current-page="query.pageIndex"
                    :total="total"
                    @current-change="handlePageChange"
                />
            </div>
        </div>
    </div>
</template>
<script>
import {getTrialClassApplyPageList,
    exportTrialClassApply
} from '@/api/order.js'
import {listXcxDept} from "@/api/dept";
import { pageList} from  "@/api/coach";
export default{ 
    data(){
        return{
            AllCaochList:[],
            deptList:[],
            v1:[],
            total:0,
            query:{
                createName:'',
                createDeptId:[],
                createId: null,
                endTime: "",
                info: "",
                pageIndex: 1,
                pageSize: 10,
                startTime: "",
                state: 0
            },
            dataList:[]
        }  
    },
    created(){
        this.getPageList(),
        listXcxDept().then(res => {
            if(res.code == 200){ 
                this.deptList = this.getTypeList(res.data);    
            }         
        })   
    },
    methods:{
        exportExcel(){
            if (this.query.createDeptId) {
                this.query.createDeptId = [...this.query.createDeptId.flat()];
            }
            exportTrialClassApply(this.query)
        },
        handlePageChange(e){
            this.query.pageIndex = e;
            this.getPageList();
        },
        changeDept(e){
            console.log(e);
        },
        async querySearchAsync(queryString, cb) {
            let query = {
                areaList: [],
                keyword: queryString,
                pageIndex: 1,
                pageSize: 10,
                state: "开启",
                type: "",
            }
            await pageList(query).then(res => {
                if (res.code == 200) {
                    this.AllCaochList = res.data.records
                }
            })
            let apiResult = []
            this.AllCaochList.forEach(item => {
                let obj = {}
                obj.value = item.coachName
                obj.id = item.coachId
                apiResult.push(obj)
            })
            clearTimeout(this.timeout)
            this.timeout = setTimeout(() => {
                cb(apiResult)
            }, 600)
        },
        selectCocahName(item){
        // this.student.serveCoachName = item.value
        this.query.createId = item.id
        this.query.createName = item.value
        },
        getTypeList(commodityType) {
            if (commodityType != null) {
                commodityType.forEach(items => {
                    if (items != null) {
                        if (items.children != null && items.children.length > 0) {
                            this.getTypeList(items.children);
                        } else {
                            items.children = undefined;
                        }
                    }
                });
                return commodityType;
            }
        },
        getData(){ 
            // if (this.query.createDeptId && this.query.createDeptId.length > 1) {
            //     this.query.createDeptId.splice(0, this.query.createDeptId.length - 1)
            // }
            if (this.query.createDeptId) {
                this.query.createDeptId = [...this.query.createDeptId.flat()];
            }
            this.query.pageIndex = 1
            this.getPageList()
        },
        getPageList(){ 
            getTrialClassApplyPageList(this.query).then(res=>{      
                if(res.code == 200){
                    this.dataList = res.data.records
                    this.total = res.data.total
                }
            })
        },
        disposeTime(){
            if(this.v1.length>=2){
                this.query.startTime = this.dateFormat(this.v1[0]);
                this.query.endTime = this.dateFormat(this.v1[1]);
            }
        },
        dateFormat(val) {
            var date = new Date(val);
            var y = date.getFullYear();
            var m = date.getMonth() + 1;
            m = m < 10 ? "0" + m : m;
            var d = date.getDate();
            d = d < 10 ? "0" + d : d;
            const time = parseInt(y) + "-" + parseInt(m) + "-" + parseInt(d);
            return time;
        }, 
    }
}
</script>
<style scoped>
.el-table__cell {
  position: static !important;
}
.inPut{
    margin: 0 10px;
    width:180px
}
</style>